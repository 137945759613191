import { css } from '@emotion/core';
import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

export const globalStyles = `
    html, body {
        margin: 0;
        padding: 0;
        height: 100%;
    }

    body {
        font-family: ${ds.get('type.fontFamily.system')};
        background-color: ${ds.color('secondary', 'chromium')};
    }

    #root {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
`;

export const appContentStyles = css`
    flex: 1 0 auto;
`;
