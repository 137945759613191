import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    levelBeginner: {
        id: 'classMeta.level.beginner',
        description: 'Beginner level of lab',
        defaultMessage: 'Beginner',
    },
    levelIntermediate: {
        id: 'classMeta.level.intermediate',
        description: 'Intermediate level of lab',
        defaultMessage: 'Intermediate',
    },
    levelAdvanced: {
        id: 'classMeta.level.advanced',
        description: 'Advanced level of lab',
        defaultMessage: 'Advanced',
    },
    virtual: {
        id: 'classMeta.virtual.label',
        description: 'label for virtual classrooms',
        defaultMessage: 'Virtual link',
    },
    physical: {
        id: 'classMeta.in-person.label',
        description: 'label for physical classrooms',
        defaultMessage: 'Location',
    },
    studentNumber: {
        id: 'classMeta.studentNumber.label',
        description: 'label for Student Number',
        defaultMessage: 'Student number',
    },
    skillLevel: {
        id: 'classMeta.skillLevel.label',
        description: 'label for SkillLevel',
        defaultMessage: 'Skill level',
    },
    duration: {
        id: 'classMeta.duration.label',
        description: 'label for Duration',
        defaultMessage: 'Duration',
    },
    ends: {
        id: 'classMeta.ends.label',
        description: 'label for ends',
        defaultMessage: 'Ends',
    },
    starts: {
        id: 'classMeta.starts.label',
        description: 'label for starts',
        defaultMessage: 'Starts',
    },
});

export default messages;
