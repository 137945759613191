import { css } from '@emotion/core';
import { mediaQueries as mq } from '@amzn/awspaloma-ui/dist/utils/style';

export const sectionWarningStyle = css`
    font-style: italic;
`;

export const flexWrapperStyle = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const btnContainer = css`
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 60%;
    ${mq.m} {
        justify-content: flex-end;
    }
`;

export const btnMobileStyle = css`
    width: 100%;
    margin: 0 0.5rem 8px;
    ${mq.m} {
        max-width: 170px;
        min-width: 100px !important;
    }
`;
