import React from 'react';
import { Button } from '@amzn/awsui-components-react';
import { Header, HeaderLeft, HeaderRight, Logo } from '@amzn/awspaloma-ui';
import { injectIntl } from 'react-intl';
import { logger } from '../utils';

import messages from './ClassHeader.messages';

const transformToPropKikuUrl = urlInput => {
    try {
        const url = new URL(urlInput);
        if (url.hostname.includes('kiku.np.aws.training')) {
            url.hostname = 'aws.training';
            return url.toString();
        }
        return urlInput;
    } catch (err) {
        logger.log(err);
        return urlInput;
    }
};

export const ClassHeader = ({
    intl: { formatMessage },
    callsToAction = [],
}) => (
    <Header>
        <HeaderLeft>
            <Logo color light={false} />
        </HeaderLeft>
        <HeaderRight>
            {callsToAction.map(({ locationType, url, label }) => {
                const text = label || formatMessage(messages.returnActionText);
                return (
                    locationType === 'header' && (
                        <Button
                            label={text}
                            key={url}
                            variant="link"
                            href={transformToPropKikuUrl(url)}
                        >
                            {text}
                        </Button>
                    )
                );
            })}
        </HeaderRight>
    </Header>
);

export default injectIntl(ClassHeader);
