import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    modalResume: {
        id: 'classMain.modal.resume',
        description: 'Text for resume button in concurrent',
        defaultMessage: 'Resume',
    },
    modalClose: {
        id: 'classMain.modal.close',
        description: 'Text for close button in modal',
        defaultMessage: 'Close',
    },
    modalConfirm: {
        id: 'classMain.modal.confirm',
        description: 'Text for confirm button in modal',
        defaultMessage: 'Ok',
    },
    concurrentTitle: {
        id: 'classMain.concurrent.title',
        description: 'Concurrent title inside the modal',
        defaultMessage: 'Lab already running',
    },
    concurrentContent: {
        id: 'classMain.concurrent.content',
        description: 'Concurrent content inside the modal',
        defaultMessage: 'End your current lab before starting a new one.',
    },
    pollTimeoutTitle: {
        id: 'classMain.pollTimeout.title',
        description: 'Title text for when polling has timed out',
        defaultMessage: 'Request timeout',
    },
    pollTimeoutContent: {
        id: 'classMain.pollTimeout.content',
        description: 'Main copy for when polling has timed out',
        defaultMessage:
            "We're having trouble connecting. Please try again in a minute.",
    },
    inactiveTitle: {
        id: 'classMain.inactiveTitle.title',
        description:
            'Title text for when a student tries to launch an inactive training',
        defaultMessage: 'Lab unavailable',
    },
    inactiveContent: {
        id: 'classMain.inactiveContent.title',
        description:
            'Main copy for when a student tries to launch an inactive training',
        defaultMessage: 'Please contact your instructor for details.',
    },
    classEndedContent: {
        id: 'classMain.classEndedContent.title',
        description:
            'Main copy for when a student tries to launch an inactive training',
        defaultMessage:
            'This class has ended and the lab is no longer available.',
    },
    genericTitle: {
        id: 'classMain.generic.title',
        description: 'Title text for when an unknown error occurred',
        defaultMessage: 'Something went wrong',
    },
    genericContent: {
        id: 'classMain.generic.content',
        description: 'Main copy for when a generic error as occurred',
        defaultMessage: "We're having trouble. Please try again in a minute.",
    },
});

export default messages;
