import React, { useEffect } from 'react';
import { HeroBanner, LearningObjectKindTypes } from '@amzn/awspaloma-ui';
import _get from 'lodash/get';
import Loader from '../Loader';
import messages from './ClassPage.messages';
import ClassHeader from '../ClassHeader';
import ClassMain from '../ClassMain';
import ClassErrorModal from '../ClassErrorModal/ClassErrorModal';
import { useQuery } from '../utils';
import { useUser } from '../utils/user';
import { performFetch } from '../sagas';
import { useLangLocale } from '../LangLocaleProvider';

import './ClassPage.scss';

const ClassPage = ({ intl: { formatMessage }, classroomId }) => {
    const message = formatMessage(messages.classPageLongLoad);
    const { localeSet } = useLangLocale();
    const { getIdToken } = useUser();
    const { fetchDispatch, state } = useQuery('classData');
    const { data, error, loading } = state;
    const callsToAction = _get(data, 'classroom.callsToAction', []);
    const title = _get(data, 'course.title');

    const messageAfterSecondAndAHalf = error => {
        return error && error.retries > 4 && message;
    };

    const locationType =
        _get(data, 'classroom.locationData.locationType') === 'physical'
            ? 'InPersonClass'
            : 'VirtualClassroom';

    const getClassroomData = async () => {
        const idToken = await getIdToken()
        performFetch(fetchDispatch, {
            idToken,
            params: { path: `/${classroomId}` },
            api: state,
            retryOptions: {
                retries: 7,
                factor: 2,
                randomize: false,
                minTimeout: 200,
                maxTimeout: 3 * 1000,
                retryOnStatus: 403,
            },
        });
    };

    useEffect(() => {
        getClassroomData();
    }, [classroomId]);

    useEffect(() => {
        const classLangLocale = _get(data, 'classroom.langLocale');
        if (classLangLocale) {
            localeSet(classLangLocale);
        }
    }, [data, localeSet]);

    return (
        <Loader isLoading={loading} message={messageAfterSecondAndAHalf(error)}>
            <ClassHeader callsToAction={callsToAction} />
            {title && (
                <HeroBanner
                    title={title}
                    titleClassName="hero-banner__title"
                    iconSize="mediumLarge"
                    iconTextSize="s"
                    iconText={formatMessage(
                        messages[`classPageLocation${locationType}`]
                    )}
                    kindType={LearningObjectKindTypes[locationType]}
                />
            )}
            <ClassMain classData={data} />
            {!!error && <ClassErrorModal error={error} />}
        </Loader>
    );
};

export default ClassPage;
