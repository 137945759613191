/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Card, PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';
import { injectIntl } from 'react-intl';

import { SelfPacedLab } from '@amzn/awspaloma-ui/dist/utils/KindTypes';
import moment from 'moment';
import 'moment/min/locales';
import { parse } from '@sandfox/arn';

import messages from './ClassLabList.messages';
import './ClassLabList.scss';

const tagStyles = {
    textTransform: 'uppercase',
};

const translateContentLevel = (number, formatMessage) => {
    const mappings = {
        100: messages.levelFundamental,
        200: messages.levelIntermediate,
        300: messages.levelAdvanced,
    };

    return mappings[number] ? formatMessage(mappings[number]) : '';
};

const translateKindType = (arn, formatMessage) => {
    const { resource } = parse(arn);
    const kindIdentifier = resource.split(/\//)[0];
    // only self-paced labs support initially
    switch (kindIdentifier) {
        case 'spl':
            return {
                displayKind: formatMessage(messages.selfPacedLab),
                kindType: SelfPacedLab,
            };
        default:
            return { displayKind: '', kindType: '' };
    }
};

const translateDurationUnit = (value, unit, locale) => {
    if (unit === 'min') {
        unit = 'minute';
    }
    return moment
        .duration(value, unit)
        .locale(locale)
        .humanize();
};

const buildComponentLearningObjects = (
    { course, content = [] },
    intl,
    onClassClick,
    activeTrainings
) => {
    // only render if the ARN is in both data.course.content and as a key in data.content
    const contentList = course.content.filter(v => content[v]);
    return contentList.map(contentId => {
        content[contentId].active = activeTrainings.has(contentId);
        return buildLearningObject(contentId, content, intl, onClassClick);
    });
};

const buildLearningObject = (
    id,
    content,
    { formatMessage, locale },
    onClassClick
) => {
    const {
        active,
        level,
        duration,
        durationUnit,
        description,
        title,
    } = content[id];
    const { displayKind, kindType } = translateKindType(id, formatMessage);
    const displaySkillLevel = translateContentLevel(level, formatMessage);
    const displayDuration = translateDurationUnit(
        duration,
        durationUnit,
        locale
    );
    return {
        active,
        title,
        description,
        displaySkillLevel,
        displayDuration,
        displayKind,
        kindType,
        id,
        action: onClassClick.bind(null, id),
    };
};

const ClassLabList = ({
    intl,
    data,
    onClassClick,
    activeTrainings = new Set([]),
}) => {
    return !data ? null : (
        <div data-test="lab-list">
            {buildComponentLearningObjects(
                data,
                intl,
                onClassClick,
                activeTrainings
            ).map(
                ({
                    active,
                    action,
                    id,
                    title,
                    description,
                    displaySkillLevel,
                    displayDuration,
                    kindType,
                    displayKind,
                }) => (
                    <div
                        key={id}
                        className={!active ? 'not-active-wrapper' : undefined}
                    >
                        <Card
                            id="some-id"
                            className="some-name"
                            gradientName={kindType}
                            action={active ? action : undefined}
                            variant={!active ? 'completed' : undefined}
                        >
                            <Card.Header>{title}</Card.Header>
                            <Card.Metadata>
                                <Card.Tag
                                    css={tagStyles}
                                    color={ds.color('secondary', 'brinkPink')}
                                    fullWidth
                                >
                                    {displayKind}
                                </Card.Tag>
                                <Card.Tag css={tagStyles}>
                                    {displaySkillLevel}
                                </Card.Tag>
                                <Card.Tag css={tagStyles}>
                                    {displayDuration}
                                </Card.Tag>
                            </Card.Metadata>
                            <Card.Description>{description}</Card.Description>
                            <Card.ActionBar
                                badgeText={
                                    !active
                                        ? intl.formatMessage(
                                              messages.inactiveBadgeText
                                          )
                                        : undefined
                                }
                            >
                                {active ? (
                                    <Button
                                        variant="flat"
                                        type="button"
                                        text={intl.formatMessage(
                                            messages.launchLab
                                        )}
                                        size="small"
                                        onClick={action}
                                    />
                                ) : (
                                    undefined
                                )}
                            </Card.ActionBar>
                        </Card>
                    </div>
                )
            )}
        </div>
    );
};

export default injectIntl(ClassLabList);
