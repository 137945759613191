import '@amzn/awsui-components-react/index.css';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import get from 'lodash.get';

import App, { LOCALE_KEY } from './App';
import { parseURIParams } from './utils';
import LangLocaleProvider from './LangLocaleProvider';

const initLocale =
    sessionStorage.getItem(LOCALE_KEY) ||
    get(
        parseURIParams(window.location.search.substring(1)),
        'locale',
        window.navigator.language || 'en-US'
    );

render(
    <LangLocaleProvider value={initLocale}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </LangLocaleProvider>,
    document.getElementById('root')
);
