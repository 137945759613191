import { css } from '@emotion/core';
import { mediaQueries as mq } from '@amzn/awspaloma-ui/dist/utils/style';

export const mainStyles = css`
    padding-top: 32px;
    padding-bottom: 80px;

    & > *:first-of-type {
        ${mq.l} {
            flex-direction: row-reverse !important;
        }
    }
`;

export const metaContainerStyles = css`
    margin: 0 auto;

    ${mq.s} {
        max-width: 100%;
    }

    ${mq.l} {
        max-width: 31%;
    }
`;

export const descriptionStyles = css`
    margin-top: 32px;

    ${mq.l} {
        margin-top: 0;
    }
`;

export const cardListStyles = css`
    margin-top: 15px;
`;

export const classLabListTitle = css`
    margin-top: 32px;
`;
