/** @jsx jsx */
import { PureComponent } from 'react';
import { jsx } from '@emotion/core';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Callback, UserData } from 'react-oidc';
import { injectIntl } from 'react-intl';
import _get from 'lodash/get';

import Layout from '../Layout';
import ErrorPage from '../ErrorPage';
import ClassPage from '../ClassPage';
import withUseCode from '../withUseCode';
import { configureAuth } from '../.config/configureAuth';
import { parseURIParams, recordTimingMetrics } from '../utils';
import { AppError, AUTH_ERROR_TYPE } from '../utils/errors';

const PATH_KEY = 'originalPath';
const SEARCH_KEY = 'urlSearch';
export const LOCALE_KEY = 'langLocale';

class App extends PureComponent {
    static contextType = UserData;

    constructor(props) {
        super(props);

        this.window = props.depWindow || window;

        const { Authenticator, userManager } = configureAuth({
            render: withUseCode(injectIntl(ClassPage)),
            globals: this.window,
        });

        this.Authenticator = Authenticator;
        this.userManager = userManager;

        const { pathname, search } = props.location;
        if (pathname.length > 1 && pathname !== '/callback') {
            // set persisting values to have after authentication
            this.window.sessionStorage.setItem(PATH_KEY, pathname);
            this.window.sessionStorage.setItem(LOCALE_KEY, props.intl.locale);
            this.window.sessionStorage.setItem(SEARCH_KEY, search);
        }
    }

    handleError = err => new AppError(err.message);

    UNSAFE_componentWillMount() {
        // NOTE: need to allow for injecting a window object for tests because
        // jsdom overwrites mocking addEventListener inside componentWillMount
        const win = this.props.depWindow || window;
        // Collect metrics AFTER the page has loaded
        win.addEventListener('load', recordTimingMetrics);
        // Collect JS errors that bubble up to window.onerror
        win.addEventListener('error', this.handleError);
    }

    componentWillUnmount() {
        const win = this.props.depWindow || window;
        // Clean up event listeners
        win.removeEventListener('load', recordTimingMetrics);
        win.removeEventListener('error', this.handleError);
    }

    render() {
        const { Authenticator, userManager } = this;

        return (
            <Layout>
                <Switch>
                    <Route
                        path="/callback"
                        render={routeProps => (
                            <Callback
                                onSuccess={() => {
                                    const search =
                                        this.window.sessionStorage.getItem(
                                            SEARCH_KEY
                                        ) || '';
                                    const path =
                                        this.window.sessionStorage.getItem(
                                            PATH_KEY
                                        ) + search || '/error';
                                    this.window.sessionStorage.removeItem(
                                        PATH_KEY
                                    );
                                    this.window.sessionStorage.removeItem(
                                        LOCALE_KEY
                                    );
                                    this.window.sessionStorage.removeItem(
                                        SEARCH_KEY
                                    );
                                    routeProps.history.push(path);
                                }}
                                onError={error => {
                                    new AppError(
                                        'Error getting authenticated',
                                        { type: AUTH_ERROR_TYPE }
                                    );
                                    routeProps.history.push('/error');
                                }}
                                userManager={userManager}
                            />
                        )}
                    />
                    <Route
                        path="/class/:id"
                        render={({ match, location: { search } }) => {
                            const classroomId = _get(match, 'params.id', '');
                            const { accessCode } = parseURIParams(
                                search.substring(1)
                            );

                            this.window.history.pushState(
                                this.window.history.state,
                                '',
                                this.window.location.pathname
                            );

                            return (
                                <Authenticator
                                    classroomId={classroomId}
                                    accessCode={
                                        accessCode
                                            ? this.window.decodeURIComponent(
                                                  accessCode
                                              )
                                            : undefined
                                    }
                                />
                            );
                        }}
                    />
                    <Route component={ErrorPage} />
                </Switch>
            </Layout>
        );
    }
}

export default withRouter(injectIntl(App));
