import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    descriptionHeading: {
        id: 'classMain.description.heading',
        description: 'Heading for classroom description section',
        defaultMessage: 'Description',
    },
});

export default messages;
