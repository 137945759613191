/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Typography } from '@amzn/awspaloma-ui';

/**
 * Section component for adding padding and max-width with auto left and right margins
 * @param backgroundColor - A color to apply to the section background
 * @param children - React nodes to render in the section
 * @param ariaRole - An aria role to apply to the section for accessiblity
 * @returns {React.Element}
 */
const Section = ({ backgroundColor, children, ariaRole, className }) => (
    <div
        data-test="section-wrapper"
        css={{ backgroundColor }}
        className={className}
    >
        <section role={ariaRole}>{children}</section>
    </div>
);

Section.propTypes = {
    backgroundColor: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    ariaRole: PropTypes.string,
};

const titleStyles = css`
    margin-bottom: 8px !important;
`;

export const SectionTitle = ({ children, ...rest }) => (
    <Typography variant="h2" {...rest} css={titleStyles}>
        {children}
    </Typography>
);

export default Section;
