/** @jsx jsx */
import { jsx } from '@emotion/core';
import { injectIntl } from 'react-intl';
import { Row, Box, Typography } from '@amzn/awspaloma-ui';
import get from 'lodash.get';

import messages from './ClassMain.messages';
import {
    mainStyles,
    metaContainerStyles,
    descriptionStyles,
    classLabListTitle,
} from './ClassMain.styles';
import Section, { SectionTitle } from '../Section';
import ClassMeta from '../ClassMeta';
import ClassLabListHeader from '../ClassLabListHeader';
import ClassLabList from '../ClassLabList';
import StartTrainingQuery from '../StartTrainingQuery';
import TrainingPermissionsQuery from '../TrainingPermissionsQuery';

const ClassMain = ({ intl: { formatMessage }, classData }) => {
    const abstract = get(classData, 'course.abstract');
    const langLocale = get(classData, 'course.langLocale');
    const startsOn = get(classData, 'classroom.startsOn');
    const endsOn = get(classData, 'classroom.endsOn');
    const classId = get(classData, 'classroom.classroomId');

    return (
        <StartTrainingQuery
            formatMessage={formatMessage}
            classroomId={classId}
            langLocale={langLocale}
            endsOn={endsOn}
        >
            <TrainingPermissionsQuery>
                {props => {
                    const {
                        queries: { callStartTraining },
                        data: { activeTrainings },
                    } = props;

                    return (
                        <Row trigger="m" padded css={mainStyles}>
                            <Box
                                widths={{ m: 5 / 6, l: 1 / 3 }}
                                css={metaContainerStyles}
                            >
                                <ClassMeta data={classData} />
                            </Box>
                            <Box widths={{ m: 1, l: 2 / 3 }}>
                                {abstract && (
                                    <Section
                                        ariaRole="main"
                                        data-test="main-section"
                                        css={descriptionStyles}
                                    >
                                        <SectionTitle data-test="description-heading">
                                            {formatMessage(
                                                messages.descriptionHeading
                                            )}
                                        </SectionTitle>
                                        <Typography
                                            variant="body1"
                                            data-test="abstract"
                                        >
                                            {abstract}
                                        </Typography>
                                    </Section>
                                )}
                                <Section css={classLabListTitle}>
                                    {startsOn && (
                                        <ClassLabListHeader
                                            startsOn={startsOn}
                                            classId={classId}
                                        />
                                    )}
                                    <div data-test="lab-list">
                                        <ClassLabList
                                            data={classData}
                                            onClassClick={callStartTraining}
                                            activeTrainings={activeTrainings}
                                        />
                                    </div>
                                </Section>
                            </Box>
                        </Row>
                    );
                }}
            </TrainingPermissionsQuery>
        </StartTrainingQuery>
    );
};

export default injectIntl(ClassMain);
