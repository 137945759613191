import { useContext } from 'react';
import { UserData } from 'react-oidc';

export function useUser() {
    const userData = useContext(UserData);
    return {
        getIdToken: () => {
            return userData.userManager.getUser().then(user => user.id_token);
        },
    };
}
