import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Button } from '@amzn/awsui-components-react';
import Loader from '../Loader';

import { useQuery } from '../utils';
import { useUser } from '../utils/user';
import { performFetch } from '../sagas';
import { generateIdempotencyToken } from '../utils';
import messages from './withUseCode.messages';

const withUseCode = Component =>
    injectIntl(({ intl: { formatMessage }, accessCode, classroomId }) => {
        const { getIdToken } = useUser();
        const { fetchDispatch, state } = useQuery('useAccessCode');
        const { error, data } = state;
        const [showModal, setShowModal] = useState(true);

        useEffect(() => {
            if (!accessCode || !classroomId) return;

            getIdToken().then(idToken => {
                performFetch(fetchDispatch, {
                    idToken,
                    params: {
                        method: 'POST',
                        body: {
                            classroomId,
                            code: accessCode,
                            idempotencyToken: generateIdempotencyToken(),
                        },
                    },
                    api: state,
                });
            });
        }, [accessCode, classroomId]);

        return (
            <Loader
                isLoading={!data && accessCode}
                hasError={error}
                fallback={() => (
                    <Modal
                        visible={showModal}
                        header={formatMessage(messages.accessCodeModalTitle)}
                        footer={
                            <Button
                                className="awsui-util-f-r"
                                onClick={() => setShowModal(false)}
                                variant="primary"
                            >
                                {formatMessage(messages.accessCodeModalConfirm)}
                            </Button>
                        }
                    >
                        {formatMessage(messages.accessCodeModalContent)}
                    </Modal>
                )}
            >
                <Component classroomId={classroomId} />
            </Loader>
        );
    });

export default withUseCode;
