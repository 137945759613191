import React, { useState, useEffect, Fragment } from 'react';
import get from 'lodash.get';
import moment from 'moment-timezone';
import { Modal, Button } from '@amzn/awsui-components-react';

import Loader from '../Loader';
import messages from './StartTrainingQuery.messages';

import {
    logger,
    useQuery,
    generateIdempotencyToken,
    poll,
    openTab,
} from '../utils';
import { performFetch } from '../sagas';

import { clearData } from '../actions';
import { useUser } from '../utils/user';

const btnGenerator = (text, onClick, variant = 'primary') => ({
    text,
    onClick,
    variant,
});

const StartTrainingQuery = ({ children, queries = {}, data = {}, ...rest }) => {
    const { classroomId, langLocale, formatMessage, endsOn } = rest;

    const [modalVisible, setModalVisible] = useState(false);
    const [modalKey, setModalKey] = useState('');
    const [statlerUrl, setStatlerUrl] = useState();
    const [timeoutId, setTimeoutId] = useState(0);

    const {
        fetchDispatch: createFetchDispatch,
        state: createTrainingState,
    } = useQuery('createTraining');

    const {
        fetchDispatch: getFetchDispatch,
        state: getTrainingState,
    } = useQuery('getTraining');

    const {
        data: createTrainingData,
        loading: createTrainingLoading,
        error: createTrainingError,
    } = createTrainingState;
    const createTrainingStatus = get(createTrainingData, 'fulfillmentStatus');

    const {
        data: getTrainingData,
        loading: getTrainingLoading,
    } = getTrainingState;
    const getTrainingStatus = get(getTrainingData, 'fulfillmentStatus');

    const {
        loading: combinedLoading,
        fulfillmentStatus: combinedFulfillmentStatus,
    } = Object.assign({}, createTrainingData, getTrainingData, {
        loading: createTrainingLoading || getTrainingLoading,
    });

    const modalContent = {
        timeout: {
            title: messages.pollTimeoutTitle,
            content: messages.pollTimeoutContent,
            btns: [btnGenerator(messages.modalClose, hideModal)],
        },
        concurrent: {
            title: messages.concurrentTitle,
            content: messages.concurrentContent,
            btns: [
                btnGenerator(messages.modalClose, hideModal, 'link'),
                btnGenerator(messages.modalResume, onResumeClick),
            ],
        },
        inactive: {
            title: messages.inactiveTitle,
            content: messages.inactiveContent,
            btns: [btnGenerator(messages.modalConfirm, hideModal)],
        },
        classEnded: {
            title: messages.inactiveTitle,
            content: messages.classEndedContent,
            btns: [btnGenerator(messages.modalConfirm, hideModal)],
        },
        generic: {
            title: messages.genericTitle,
            content: messages.genericContent,
            btns: [btnGenerator(messages.modalClose, hideModal)],
        },
    }[modalKey];

    const loading =
        (combinedFulfillmentStatus === 'pending' || combinedLoading) &&
        !(getTrainingData && modalContent);

    function hideModal() {
        setModalVisible(false);
        setModalKey('');
    }

    function onResumeClick() {
        hideModal();
        openTab(statlerUrl);
    }

    const { getIdToken } = useUser();

    const callStartTraining = async arn => {
        createFetchDispatch(clearData());
        getFetchDispatch(clearData());
        const idToken = await getIdToken();
        performFetch(createFetchDispatch, {
            params: {
                method: 'POST',
                body: {
                    arn,
                    classroomId,
                    idempotencyToken: generateIdempotencyToken(),
                    metaData: {
                        preferredLangLocale: langLocale,
                    },
                },
            },
            api: createTrainingState,
            idToken,
        });
    };

    const getTraining = async () => {
        const idToken = await getIdToken();
        performFetch(getFetchDispatch, {
            params: {
                path: `/${createTrainingData.trainingId}`,
            },
            api: getTrainingState,
            idToken,
        });
    };

    const handleError = error => {
        const key =
            {
                'Poll timeout': 'timeout',
                'Inactive training': 'inactive',
                'Class Ended': 'classEnded',
            }[error] || 'generic';

        setModalKey(key);
        setModalVisible(true);
    };

    const handleFulfillmentError = error => {
        const { name, labUrl } = error;
        if (name === 'ActiveLabsExist') {
            setModalKey('concurrent');
            setModalVisible(true);
            setStatlerUrl(`${labUrl}?locale=${langLocale}`);
        } else {
            logger.log(`Unhandled trainingSession error: ${name}`);
            setModalKey('generic');
            setModalVisible(true);
        }
    };

    const handleFulfillmentStatuses = statusData => {
        if (statusData.fulfillmentStatus === 'complete') {
            openTab(statusData.metaData.labUrl);
        } else {
            handleFulfillmentError(statusData.fulfillmentError);
        }
    };

    useEffect(() => {
        if (!createTrainingStatus) return;
        if (createTrainingStatus !== 'pending') {
            handleFulfillmentStatuses(createTrainingData);
            return;
        }

        const { start } = poll(getTraining, {
            interval: 1000,
            onError: handleError,
            onTimeoutIdChange: setTimeoutId,
        });
        start();
    }, [createTrainingStatus]);

    useEffect(() => {
        // retry until fulfillmentStatus isn't pending
        if (!getTrainingStatus || getTrainingStatus === 'pending') return;

        window.clearTimeout(timeoutId);

        handleFulfillmentStatuses(getTrainingData);
    }, [getTrainingStatus]);

    useEffect(() => {
        if (createTrainingError) {
            let msg = '';
            if (createTrainingError.status === 403) {
                if (moment().isAfter(moment.unix(endsOn))) {
                    msg = 'Class Ended';
                } else {
                    msg = 'Inactive training';
                }
            }

            handleError(msg);
        }
    }, [createTrainingError]);

    const forwardedProps = {
        ...rest,
        queries: {
            ...queries,
            callStartTraining,
        },
        data,
    };

    return (
        <Fragment>
            <Loader isLoading={loading}>
                {children.props
                    ? React.cloneElement(children, forwardedProps)
                    : children(forwardedProps)}
            </Loader>
            {modalContent ? (
                <Modal
                    visible={modalVisible}
                    data-test="start-training-query-modal"
                    header={formatMessage(modalContent.title)}
                    footer={
                        <span className="awsui-util-f-r">
                            {modalContent.btns.map(({ text, ...modalRest }) => (
                                <Button
                                    key={text.id}
                                    size="small"
                                    {...modalRest}
                                >
                                    {formatMessage(text)}
                                </Button>
                            ))}
                        </span>
                    }
                >
                    {formatMessage(modalContent.content)}
                </Modal>
            ) : null}
        </Fragment>
    );
};

export default StartTrainingQuery;
