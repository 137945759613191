import { PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';

export const errorContainerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    textAlign: 'center',

    h1: {
        fontSize: '10em',
        margin: '0 0 0.5em',
        lineHeight: '10px',
        color: ds.get('colors.brand.amazonOrange'),
    },

    p: {
        fontSize: '1.2em',
        LineHeight: '26px',
        color: ds.color('semantic.info'),
    },
};
