/** @jsx jsx */

import { useEffect, useState, useRef } from 'react';
import { jsx } from '@emotion/core';
import { injectIntl } from 'react-intl';
import _get from 'lodash/get';
import { Modal, Button as PolarisButton } from '@amzn/awsui-components-react';
import { Typography, Button } from '@amzn/awspaloma-ui';

import { isBeforeTimestamp, adjustUnixTimestamp, useQuery } from '../utils';
import { performFetch } from '../sagas';
import { SectionTitle } from '../Section';
import messages from './ClassLabListHeader.messages';
import {
    sectionWarningStyle,
    flexWrapperStyle,
    btnMobileStyle,
    btnContainer,
} from './ClassLabListHeader.styles';
import { useUser } from '../utils/user';

const ClassLabListHeader = ({ intl: { formatMessage }, startsOn, classId }) => {
    const hasTheClassStarted = isBeforeTimestamp(adjustUnixTimestamp(startsOn));
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [guideType, setGuideType] = useState('');
    const [ltiParams, setLtiParams] = useState({});
    const { fetchDispatch, state: studentGuideState } = useQuery('getGuides');
    const {
        data: studentGuideData,
        loading: studentGuideLoading,
        error: studentGuideError,
    } = studentGuideState;

    const { getIdToken } = useUser();

    const onClickStudentGuide = async (ev, type) => {
        ev.preventDefault();
        setGuideType(type);
        const idToken = await getIdToken()
        performFetch(fetchDispatch, {
            params: {
                method: 'GET',
                path: `/${classId}`,
            },
            api: studentGuideState,
            idToken,
        });
    };

    const formRef = useRef();

    const GuideBtn = ({ guideType, btnText }) => {
        return (
            <Button
                css={btnMobileStyle}
                variant="primary"
                size="small"
                className="student-guide-btn"
                data-test={`${guideType}-guide-btn`}
                text={formatMessage(btnText)}
                icon-align="left"
                icon="external-link"
                iconAlign="left"
                onClick={ev => onClickStudentGuide(ev, guideType)}
                disabled={!hasTheClassStarted || studentGuideLoading}
            />
        );
    };

    useEffect(() => {
        if (studentGuideError) {
            setErrorModalOpen(true);
        } else if (studentGuideData && Object.keys(studentGuideData).length) {
            // cjab: i don't like this, but jest has some weird behaviors
            // with React hooks where the guideType state doesn't propagate
            // properly even if it works fine in the browser. Also, it's
            // (arguably) safer to default to return something
            // rather than nothing
            const which = guideType || 'student';
            const params = _get(studentGuideData, which, {});
            setLtiParams(params);
        }
    }, [studentGuideData, studentGuideError]);

    useEffect(() => {
        if (Object.keys(ltiParams).length) {
            formRef.current.submit();
        }
    }, [ltiParams]);

    return (
        <div>
            <div css={flexWrapperStyle}>
                <SectionTitle>
                    {formatMessage(messages.sectionTitle)}
                </SectionTitle>
                <Modal
                    visible={errorModalOpen}
                    header={formatMessage(messages.studentGuideErrorTitle)}
                    footer={
                        <PolarisButton
                            variant="primary"
                            onClick={() => setErrorModalOpen(false)}
                        >
                            {formatMessage(
                                messages.studentGuideErrorModalActionButton
                            )}
                        </PolarisButton>
                    }
                >
                    {formatMessage(messages.studentGuideErrorContent)}
                </Modal>
                <div css={btnContainer}>
                    <GuideBtn
                        guideType="lab"
                        btnText={messages.labGuideButtonText}
                    />
                    <GuideBtn
                        guideType="student"
                        btnText={messages.callToActionButtonText}
                    />
                </div>
            </div>
            <form
                action="https://bc.vitalsource.com/books/book"
                method="POST"
                target="_blank"
                id="student-guide-form"
                rel="noopener noreferrer"
                ref={formRef}
            >
                {ltiParams &&
                    Object.keys(ltiParams).map(v => (
                        <input
                            key={v}
                            name={v}
                            value={ltiParams[v]}
                            type="hidden"
                        />
                    ))}
            </form>
            {!hasTheClassStarted && (
                <Typography
                    css={sectionWarningStyle}
                    variant="body3"
                    data-test="too-early"
                >
                    {formatMessage(messages.tooEarlyWarning)}
                </Typography>
            )}
        </div>
    );
};

export default injectIntl(ClassLabListHeader);
