import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    errorMessage: {
        id: 'errorPage.message',
        description: 'Error code message',
        defaultMessage: 'Not all who wander are lost',
    },
});

export default messages;
