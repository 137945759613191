import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    returnActionText: {
        id: 'header.returnActionText',
        description:
            'The default button text displayed in the header for returning to the referrer application',
        defaultMessage: 'Home',
    },
});

export default messages;
