/** @jsx jsx */
import { Fragment } from 'react';
import { jsx } from '@emotion/core';
import { injectIntl } from 'react-intl';
import { Typography, Link, PalomaDesignSystem as ds } from '@amzn/awspaloma-ui';
import moment from 'moment-timezone';

import {
    formatDuration,
    formatTimezone,
    formatUTC,
    formatTime,
} from './metaFormatters';
import messages from './ClassMeta.messages';
import styles from './ClassMeta.module.css';

const wrapperStyles = {
    backgroundColor: ds.color('primary', 'hydrogen'),
    padding: ds.spacing(2),
    boxShadow: ds.get('boxShadow.shadowOne'),
};

const listStyles = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 0,

    '& > *': {
        paddingTop: ds.spacing(1),
        paddingBottom: ds.spacing(1),

        '&:nth-of-type(1)': {
            paddingTop: 0,
        },

        '&:nth-of-type(n + 2)': {
            borderTop: `1px solid ${ds.color('secondary', 'chromium')}`,
        },

        '&:last-of-type': {
            paddingBottom: 0,
        },
    },
};

const labelStyles = {
    flex: '0 0 80px',
    textAlign: 'right',
};

const valueStyles = {
    paddingLeft: ds.spacing(2),
    flex: '0 0 calc(100% - 96px)',
    marginLeft: 0,
};

const courseLevelMap = formatMessage => ({
    100: formatMessage(messages.levelBeginner),
    200: formatMessage(messages.levelIntermediate),
    300: formatMessage(messages.levelAdvanced),
});

const MetaRowLabel = ({ children }) => (
    <dt css={labelStyles}>
        <Typography
            variant="body2"
            color={ds.color('secondary', 'platinum')}
            weight="bold"
        >
            {children}
        </Typography>
    </dt>
);

export const LongDate = ({ time, timezone }) => {
    return (
        <Fragment>
            {formatTime(time)}
            <br />
            {`${formatTimezone(timezone)} ${formatUTC(time)}`}
        </Fragment>
    );
};

const LocationMetaRow = ({ virtualUrl, locationType, physicalAddress }) => {
    if (locationType === 'physical') {
        const { addressLine1, addressLine2, city, state, postalCode, country } =
            physicalAddress || {};

        return (
            <dd css={valueStyles}>
                <Typography variant="body2">
                    {addressLine1} <br />
                    {addressLine2} <br />
                    {city}, {state} {postalCode}, {country}
                </Typography>
            </dd>
        );
    } else {
        return (
            <dd className={styles.overflowHiddenText} css={[valueStyles]}>
                <Link
                    href={virtualUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Typography
                        className={styles.overflowHiddenText}
                        variant="body2"
                    >
                        {virtualUrl}
                    </Typography>
                </Link>
            </dd>
        );
    }
};

export const ClassMeta = ({
    intl: { formatMessage, locale },
    data,
    ...rest
}) => {
    if (!data) return null;
    const { startsOn, endsOn, locationData, studentContext } = data.classroom;
    const level = data.course.level;
    const { locationType, timezone, virtualUrl, physicalAddress } =
        locationData || {};

    const starts = moment.tz(startsOn * 1000, timezone);
    const ends = moment.tz(endsOn * 1000, timezone);

    return (
        <div {...rest} css={wrapperStyles}>
            <dl css={listStyles}>
                <Fragment>
                    <MetaRowLabel>
                        {formatMessage(messages[locationType])}
                    </MetaRowLabel>
                    <LocationMetaRow
                        locationType={locationType}
                        virtualUrl={virtualUrl}
                        physicalAddress={physicalAddress}
                    />
                </Fragment>

                {startsOn && (
                    <Fragment>
                        <MetaRowLabel>
                            {formatMessage(messages.starts)}
                        </MetaRowLabel>
                        <dd css={valueStyles} data-test="starts">
                            <Typography variant="body2">
                                <LongDate time={starts} timezone={timezone} />
                            </Typography>
                        </dd>
                    </Fragment>
                )}
                {endsOn && (
                    <Fragment>
                        <MetaRowLabel>
                            {formatMessage(messages.ends)}
                        </MetaRowLabel>
                        <dd css={valueStyles} data-test="ends">
                            <Typography variant="body2">
                                <LongDate time={ends} timezone={timezone} />
                            </Typography>
                        </dd>
                    </Fragment>
                )}
                {startsOn && endsOn && (
                    <Fragment>
                        <MetaRowLabel>
                            {formatMessage(messages.duration)}
                        </MetaRowLabel>
                        <dd css={valueStyles} data-test="duration">
                            <Typography variant="body2">
                                {formatDuration(starts, ends, locale)}
                            </Typography>
                        </dd>
                    </Fragment>
                )}
                {level && (
                    <Fragment>
                        <MetaRowLabel>
                            {formatMessage(messages.skillLevel)}
                        </MetaRowLabel>
                        <dd css={valueStyles} data-test="level">
                            <Typography variant="body2">
                                {courseLevelMap(formatMessage)[level]}
                            </Typography>
                        </dd>
                    </Fragment>
                )}
                {studentContext && (
                    <Fragment>
                        <MetaRowLabel>
                            {formatMessage(messages.studentNumber)}
                        </MetaRowLabel>
                        <dd css={valueStyles} data-test="student-context">
                            <Typography variant="body2">
                                {studentContext}
                            </Typography>
                        </dd>
                    </Fragment>
                )}
            </dl>
        </div>
    );
};

export default injectIntl(ClassMeta);
