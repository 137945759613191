/** @jsx jsx */
import { Global, jsx } from '@emotion/core';

import Footer from '../Footer';
import { globalStyles } from './Layout.styles';
import './Layout.css';

const Layout = ({ children }) => (
    <div className="awsui">
        <Global styles={globalStyles} />
        <div className="app-content">{children}</div>
        <Footer />
    </div>
);

export default Layout;
