import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    accessCodeModalTitle: {
        id: 'accessCodeModal.title',
        description: 'Title text for when an access code is not usable',
        defaultMessage:
            'Oops! There was an error processing your registration.',
    },
    accessCodeModalContent: {
        id: 'accessCodeModal.content',
        description: 'Main copy for when an access code is not usable',
        defaultMessage: 'Please contact your instructor for assistance.',
    },
    accessCodeModalConfirm: {
        id: 'accessCodeModal.confirm',
        description: 'Copy for confirm button',
        defaultMessage: 'Ok',
    },
});

export default messages;
