/** @jsx jsx */
import { jsx } from '@emotion/core';
import { injectIntl } from 'react-intl';

import ClassHeader from '../ClassHeader';
import { errorContainerStyle } from './ErrorPage.styles';
import messages from './ErrorPage.messages';

const ErrorPage = ({ intl: { formatMessage } }) => (
    <section>
        <ClassHeader />
        <div css={errorContainerStyle}>
            <h1>404</h1>
            <p>{formatMessage(messages.errorMessage)}</p>
        </div>
    </section>
);

export default injectIntl(ErrorPage);
