import { defineMessages } from 'react-intl.macro';

const messages = defineMessages({
    selfPacedLab: {
        id: 'classLabList.kindType.selfPacedLab',
        description: 'Kind Type for Self-Paced Lab',
        defaultMessage: 'Self-Paced Lab',
    },
    levelFundamental: {
        id: 'classLabList.level.fundamental',
        description: 'Display for 100 course level',
        defaultMessage: 'Fundamental',
    },
    levelIntermediate: {
        id: 'classLabList.level.intermediate',
        description: 'Display for 200 course level',
        defaultMessage: 'Intermediate',
    },
    levelAdvanced: {
        id: 'classLabList.level.advanced',
        description: 'Display for 300 course level',
        defaultMessage: 'Advanced',
    },
    activeBadgeText: {
        id: 'classLabList.active.badge',
        description: 'The text to display in card badge for an active lab',
        defaultMessage: 'ACTIVE',
    },
    inactiveBadgeText: {
        id: 'classLabList.inactive.badge',
        description: 'The text to display in card badge for an inactive lab',
        defaultMessage: 'INACTIVE',
    },
    toggleActivate: {
        id: 'classLabList.toggle.activate',
        description: 'The tooltip to display for activating a lab',
        defaultMessage: 'Activate',
    },
    toggleDeactivate: {
        id: 'classLabList.toggle.deactivatew',
        description: 'The tooltip to display for deactivating a lab',
        defaultMessage: 'Deactivate',
    },
    launchLab: {
        id: 'classLabList.launch',
        description: 'The launch lab button text',
        defaultMessage: 'Launch',
    },
});

export default messages;
